<template lang="pug">
include ../../../configs/template
div.text-left.row.pa-10
  div(v-if="sailorDocument.record_type === 'Трудова книжка, довідка про фаховий стаж, ремонт, практику, тощо'").row
    div.col-12
      +data-info('typeDoc', 'sailorDocument.record_type')
    div.col-sm-12.col-md-6
      div.row
        div.col-12
          +data-info('responsibility', 'getDirectoryObject({ id: sailorDocument.responsibility_work_book, value: "responsibilityWorkBook"})?.[nameLang]')
      div.row
        div(v-if="sailorDocument.date_start").col-6
          +data-info('periodStart', 'getDateFormat(sailorDocument.date_start)')
        div(v-if="sailorDocument.date_end").col-6
          +data-info('periodEnd', 'getDateFormat(sailorDocument.date_end)')
        div(v-if="sailorDocument.days_work").col-12
          +data-info('totalDays', 'sailorDocument.days_work')
    div(v-if="(checkAccess('backOffice') || checkAccess('verifierBackOfficeSecond')) && sailorDocument.position").col-sm-12.col-md-6
      +data-info('positionOnShip', 'getDirectoryObject({ id: sailorDocument.position, value: "positionsShip" })[nameLang]')
    div.col-sm-12.col-md-6
      +data-info('workPlace', 'sailorDocument.place_work')
    div.col-sm-12.col-md-6
      +data-info('bookPractical', 'sailorDocument.book_registration_practical ? $t("present") : $t("missingFemale")')
    div(v-if="checkAccess('document-author-view') && sailorDocument.created_by").col-sm-12.col-md-6
      +data-info('recordAuthor', 'sailorDocument.created_by.name')
    div(v-if="sailorDocument.created_by").col-sm-12.col-md-6
      +data-info('createDate', 'sailorDocument.created_by.date')
    div(v-if="checkAccess('verification-author-view') && !!sailorDocument.verificated_by").col-sm-12.col-md-6
      +data-info('verifier', 'sailorDocument.verificated_by.name')
    div(v-if="sailorDocument.verificated_by").col-sm-12.col-md-6
      +data-info('verificationDate', 'sailorDocument.verificated_by.date')
    div.col-sm-12.col-md-6
      +data-info-status('status', 'sailorDocument.status_document[nameLang] || getDirectoryObject({ value: "statuses", id: sailorDocument.status_document })[nameLang]', 'getStatus(sailorDocument.status_document.id || sailorDocument.status_document)')
    div.col-sm-12.col-md-6
      +data-info('isConfirmedExperience', 'sailorDocument.is_confirmed ? $t("yes") : $t("no")')

  div(v-else).row
    h5.col-12 {{ $t('mainInfo') }}:
    div.col-sm-12.col-md-6
      +data-info('typeDoc', 'sailorDocument.record_type')
    div.col-sm-12.col-md-6
      +data-info('captain', 'sailorDocument.full_name_master')
    div.col-sm-12.col-md-6
      +data-info('captainEng', 'sailorDocument.full_name_master_eng ')
    div.col-sm-12.col-md-6
      +data-info('ownerShip', 'sailorDocument.ship_owner')
    div.col-sm-12.col-md-6
      +data-info('nameShip', 'sailorDocument.name_vessel')
    div.col-sm-12.col-md-6
      +data-info('numShip', 'sailorDocument.number_vessel')
    div.col-sm-12.col-md-6
      +data-info('typeShip', 'getDirectoryObject({ value: "typeShip", id: sailorDocument.type_vessel })[nameLang]')
    div.col-sm-12.col-md-6
      +data-info('portShip', 'sailorDocument.port_of_registration')
    div.col-sm-12.col-md-6
      +data-info('modeShipping', 'getDirectoryObject({ value: "modeShipping", id: sailorDocument.mode_of_navigation})[nameLang]')
    h5.col-12 {{ $t('ttx') }}:
    div.col-sm-12.col-md-6
      +data-info('grossCapacity', 'sailorDocument.gross_capacity')
    div.col-sm-12.col-md-6
      +data-info('typeGEU', 'getDirectoryObject({ id: sailorDocument.type_geu, value: "typeGEU"})[nameLang]')
    div.col-sm-12.col-md-6
      +data-info('powerGEU', 'sailorDocument.propulsion_power')
    div.col-sm-12.col-md-6
      +data-info('levelRefrigerantPlant', 'sailorDocument.level_refriger_plant')
    div.col-sm-12.col-md-6
      +data-info('coldProductivity', 'sailorDocument.refrigerating_power')
    div.col-sm-12.col-md-6
      +data-info('elEquipmentPower', 'sailorDocument.electrical_power')
    div.col-sm-12.col-md-6
      +data-info('apparatusGMZLB', 'sailorDocument.equipment_gmzlb ? $t("present") : $t("missingFemale")')
    h5.col-12 {{ $t('port') }}:
    div.col-sm-12.col-md-6
      +data-info('swimArea', 'sailorDocument.trading_area')
    div.col-sm-12.col-md-6
      +data-info('swimPorts', 'sailorDocument.ports_input')
    h5.col-12 {{ $t('employment') }}:
    div(v-if="sailorDocument.position").col-sm-12.col-md-6
      +data-info('positionOnShip', 'getDirectoryObject({id: sailorDocument.position, value: "positionsShip"})[nameLang]')
    div(v-if="sailorDocument.all_responsibility.length").w-100
      div(v-for="(responsibility, index) in sailorDocument.all_responsibility" :key="index")
        div(v-if="responsibility.responsibility").w-100.d-flex.flex-column.flex-wrap.px-0.mt-1
          h6.black--text.w-100.px-0 {{ getDirectoryObject({ id: responsibility.responsibility, value: 'responsibility' })[nameLang] || '-' }}{{ sailorDocument.all_responsibility.length - 1 !== index ? ';' : ''  }}
          div(v-if="responsibility.date_from").ml-4
            +data-info('periodStart', 'getDateFormat(responsibility.date_from)')
          div(v-if="responsibility.date_to").ml-4
            +data-info('periodEnd', 'getDateFormat(responsibility.date_to)')
          div(v-if="responsibility.days_work").ml-4
            +data-info('totalDays', 'responsibility.days_work')
    div.col-sm-12.col-md-6
      +data-info('repairedShip', 'sailorDocument.is_repaired ? $t("yes") : $t("no")')
    div(v-if="sailorDocument.is_repaired").col-sm-12
      div(v-if="sailorDocument.repair_date_from").col-sm-12.col-md-6
        +data-info('periodStart', 'getDateFormat(sailorDocument.repair_date_from)')
      div(v-if="sailorDocument.repair_date_to").col-sm-12.col-md-6
        +data-info('periodEnd', 'getDateFormat(sailorDocument.repair_date_to)')
      div(v-if="sailorDocument.days_repair").col-12
        +data-info('totalDays', 'sailorDocument.days_repair')
    div.col-sm-12.col-md-6
      +data-info('hirePlace', 'sailorDocument.place_start')
    div.col-sm-12.col-md-6
      +data-info('firePlace', 'sailorDocument.place_end')
    div.col-sm-12.col-md-6
      +data-info('hireDate', 'getDateFormat(sailorDocument.date_start)')
    div.col-sm-12.col-md-6
      +data-info('fireDate', 'getDateFormat(sailorDocument.date_end)')
    div.col-sm-12.col-md-6
      +data-info('bookPractical', 'sailorDocument.book_registration_practical ? $t("present") : $t("missingFemale")')
    div(v-if="checkAccess('document-author-view') && sailorDocument.created_by").col-sm-12
      div.col-12.px-0
        +data-info('recordAuthor', 'sailorDocument.created_by.name')
      div.col-12.px-0
        +data-info('createDate', 'sailorDocument.created_by.date')
    div(v-if="checkAccess('verification-author-view') && sailorDocument.verificated_by").col-12
      div.col-12.px-0
        +data-info('verifier', 'sailorDocument.verificated_by.name')
      div.col-12.px-0
        +data-info('verificationDate', 'sailorDocument.verificated_by.date')
    div.col-12.col-md-6
      +data-info('isConfirmedExperience', 'sailorDocument.is_confirmed ? $t("yes") : $t("no")')
    div.col-12.col-md-6
      +data-info-status('status', 'getDirectoryObject({value: "statuses", id:sailorDocument.status_document})[nameLang]', 'getStatus(sailorDocument.status_document)')
  div.row
    div(v-if="sailorDocument.comments?.length && [STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.INVALID, STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.IN_PROCESSING].includes(sailorDocument.status_document)").col-sm-12
      v-divider
      h5.p-0.m-0 {{$t('rejectionReasonStatement')}}:
      div(v-if="sailorDocument.comments?.length").col-sm-12
        div(v-for="comment in sailorDocument.comments" :key="comment.id").pa-2
          div(v-if="comment.additional_info.reason")
            +data-info('rejectionReason','getDirectoryObject({ value: "rejectionReasons", id: comment.additional_info.reason })[nameLang]')
          div(v-if="checkAccess('backOffice')").pt-2.py-3
            +data-info('createdBy', 'comment.created_by.name')
          div.pt-2.py-3
            +data-info('dateCreate', 'new Date(comment.created_by.date).toLocaleString()')
          div.pt-2.py-3
            +data-info('setComment', 'comment.comment')
</template>

<script>
import { getStatus, getDateFormat } from '@/mixins/main'
import { mapActions, mapGetters, mapState } from 'vuex'
import { checkAccess } from '@/mixins/permissions'
import { STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'
export default {
  name: 'SailorExperienceInfo',
  data () {
    return {
      getStatus,
      getDateFormat,
      checkAccess,
      STATUSES_DOCUMENT_CONSTANTS
    }
  },
  computed: {
    ...mapGetters(['getDirectoryObject']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      sailorDocument: state => state.sailor.experienceById
    })
  },
  mounted () {
    this.getExperienceReferencesById({ ...this.$route.params })
  },
  methods: {
    ...mapActions(['getExperienceReferencesById'])
  }
}
</script>
